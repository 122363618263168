$(document).ready(function() {
    'use strict';

    // if scroll class used
    if (!!jQuery('.c-page-head--scroll').length) {
        // when the document is scrolled 
        $(this).scroll(function() {

            // add scroll class
            if( jQuery(document).scrollTop() > 0 ){
                jQuery(".c-page-head--scroll").addClass("active");
                
            // remove
            } else {
                jQuery(".c-page-head--scroll").removeClass("active");
            }

        });

        // set mobile menu for scroll
        jQuery(".c-hamburger").addClass("scroll");
    }
  

});