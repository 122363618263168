jQuery(window).on("load", function() {
    let url = window.location.href;
    // console.log(url);
    if(url.includes('?_')){
        $("html").animate(
            {
              scrollTop: $("#c-jobs--scroll").offset().top - 190
                 }, '1000');
        // console.log('yes');
    }

    // else {
    //     console.log('no');
    // }


});
