jQuery(window).on("load", function() {

    // Foreground images slider
    if (!!jQuery('.js-slick--foreground').length) {
        // Full width slick sliders
        jQuery('.js-slick--foreground').slick({
            adaptiveHeight: false,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 6000,
            dots: false,
            fade: true,
            infinite: true
        });
    }

    // Foreground images slider
    if (!!jQuery('.js-slick--testimonials').length) {
        // Full width slick sliders
        jQuery('.js-slick--testimonials').slick({
            adaptiveHeight: true,
            arrows: true,
            autoplay: true,
            autoplaySpeed: 6000,
            dots: true,
            fade: true,
            infinite: true,
            responsive: [
                {
                    breakpoint: 760,
                    settings: {                        
                        arrows: false
                    }
                }
            ]
        });
    }

});
