$(document).ready(function () {
    'use strict'
    
        var $sidebar    = $('.js-sideBar')
        ,   $target     = $('.js-sideBar__toggle')
        ,   $closer     = $('.js-sideBar__close');
    
        $target.on('click', function() {
            $sidebar.toggleClass('is-active');
        });
    
        $closer.on('click', function() {
            $sidebar.removeClass('is-active');
        });
    
    });