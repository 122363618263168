jQuery(window).on("load", function() {

    jQuery('.js-search-toggle').on('click', function(e){

        e.preventDefault();
        // jQuery( ".c-search__modal" ).slideToggle( "slow" );
        jQuery( ".js-search-bar" ).toggle();
        jQuery( ".js-nav" ).toggle();

    });

});