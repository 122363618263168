// Navbar toggle
let opened = null
const toggleVisibility = e => e.classList.toggle('is-open')
const handleDropdown = e => {
  const clickedItem = e
  toggleVisibility(clickedItem)
  if (!opened) {
    opened = clickedItem
  } else if (opened == clickedItem) {
    opened = null
  } else {
    toggleVisibility(opened)
    opened = clickedItem
  }
}

// Hide dropdown if clicked outside element
const handleClick = e => {
  if (e.target.className.includes('c-navbar__dropdown')) {
    handleDropdown(e.target)
  } else if (opened) {
    toggleVisibility(opened)
    opened = null
  }
}
document.addEventListener('click', handleClick)

// jquery to check if element actually exists
if (!!jQuery('.js-hamburger').length) {

  // Hamburger toggle
  document.querySelector('.js-hamburger').onclick = function(){
      document.querySelector('.c-navbar').classList.toggle("hamburger-opened");
      document.querySelector('.c-hamburger').classList.toggle("is-active");
  }
  
}

// jquery to check if element actually exists
if (!!jQuery('.js-menu-open').length) {

  jQuery(document).on('click', '.js-menu-open', function(e){
    e.preventDefault();
    jQuery('.c-navbar').toggleClass('hamburger-opened');
    jQuery('.c-hamburger').toggleClass('is-active');
  }); 
  
}


// Mobile menu header fix
// get height of header
function checkSizeHeader(){
  if(jQuery(window).innerWidth() <= 768) { 

    var headerHeight = $('.c-page-head').outerHeight();
    
    jQuery('.c-navbar').css('top', headerHeight);
    jQuery('.c-navbar > ul').css('padding-bottom', headerHeight * 2);

    // Drilldown header fix
    jQuery('.c-navbar__mobile').css('top', headerHeight);
    jQuery('.c-navbar__mobile').css('padding-bottom', headerHeight * 2);
    
  }
}
checkSizeHeader();
jQuery(window).resize(checkSizeHeader);


// Drilldown menu
// Menu Overlay
jQuery(document).on('click', '.js-menu--toggle', function(e){
  e.preventDefault();
  jQuery('.js-menu').toggle();
});  

// Open next drilldown section
$(document).on('click', '.js-drilldown-open > a', function(e){
  e.preventDefault();
  $(this).next('.c-navbar__mobile__sub-menu').addClass("is-open");
});

// Move back a level on drilldown menu
$(document).on('click', '.js-drilldown-back', function(e){
  e.preventDefault();
  $(this).closest('.c-navbar__mobile__sub-menu').removeClass("is-open");
});