$(document).ready(function () {
    'use strict'
    
        /**
         * Mobile Nav
         * Basic procedure to toggle target active state
         * on either button being pressed.
         */
        $(document).on('click', '.js-mobileNav__open, .js-mobileNav__close', function() {
            $('.js-mobileNav__target').toggleClass('is-active');
        });
    
    
        $('.js-selectConvert').each(function() {
            // Generate Select
            var $select = $('<select class="js-selectDropdown c-select u-width-full" />');
    
            // Default
            var $option = $('<option value="">Choose</option>');
            $select.append($option);
    
            // Generate the options
            $(this).find('a').each(function() {
                var $option = $('<option />');
                $option.attr('value', $(this).attr('href')).html($(this).html());
                $select.append($option);
            });
    
            // Replace in the DOM
            if ($(window).width() <= 800) {
                $(this).replaceWith($select);
            }
        });
    
        // Select Dropdown click behaviour
        $('.js-selectDropdown').on('change', function() {
            window.location.href = this.value;
        });
    
    });